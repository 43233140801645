import React from "react";
import Banner from "./Banner";
import Navbar from "./Navbar";
import AboutUs from "./AboutUs";
import OurMemories from "./Memories";
import Footer from "./Footer";
import Programmes from "./Programmes";
import AdmissionInquiry from "./AdmissionInquiry";
import LocateUs from "./LocateUs";
import WhyUs from "./WhyUs";
import { useMediaQuery } from "react-responsive";

function Main() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });
  const isTabletOrMobile = useMediaQuery({
    query: "(max-device-width: 1023px)",
  });

  const deviceType = isDesktopOrLaptop
    ? "desktop"
    : isTabletOrMobile
    ? "tablet"
    : "mobile";

  return (
    <>
      <Navbar />
      <Banner />
      <AboutUs />
      <WhyUs />
      <OurMemories deviceType={deviceType} />
      <Programmes />
      <AdmissionInquiry />
      <LocateUs />
      <Footer />
    </>
  );
}

export default Main;
