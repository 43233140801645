import React, { useState } from "react";
import { LoadScript, GoogleMap, MarkerF } from "@react-google-maps/api";
import { EnvelopeAtFill, TelephoneFill, GeoFill } from "react-bootstrap-icons";
import QR from "../Assests/QR.png";

function LocateUs() {
  const [map, setMap] = useState(null);

  const mapStyles = {
    height: "400px",
    width: "100%",
    borderRadius: 10,
    border: "1px solid black",
  };

  const defaultCenter = {
    lat: 29.928036,
    lng: 78.080399,
  };

  const googleMapsAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const handleMapLoad = (map) => {
    setMap(map);
  };

  return (
    <div className="container mt-5" id="locate">
      <div className="text-center">
        <h4 style={{ fontWeight: "bold" }}>Locate Us</h4>
      </div>
      <div className="row mt-3 mb-3">
        <div className="col-md-8">
          <LoadScript googleMapsApiKey={googleMapsAPIKey}>
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={15}
              center={defaultCenter}
              onLoad={handleMapLoad}
            >
              {map && (
                <MarkerF
                  position={defaultCenter}
                  title="Young Midas School"
                  icon={{
                    url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                    scaledSize: new window.google.maps.Size(30, 30),
                    anchor: new window.google.maps.Point(15, 30),
                  }}
                />
              )}
            </GoogleMap>
          </LoadScript>
        </div>
        <div className="col-md-4">
          <div
            className="card"
            style={{
              width: "100%",
              height: "400px",
              border: "1px solid black",
              borderRadius: 10,
            }}
          >
            <div
              className="contactdetails"
              style={{ padding: "1rem", color: "purple" }}
            >
              <h5>
                <GeoFill style={{ marginRight: 5 }} />
                Address
              </h5>
              <p>A-6/31 OPPOSITE SAI SADAN APPARTMENT, (THDC), HARIDWAR</p>
              <h5>
                <EnvelopeAtFill style={{ marginRight: 5 }} />
                Email
              </h5>
              <p>youngmidas@techhabiles.com</p>
              <h5>
                <TelephoneFill style={{ marginRight: 5 }} />
                Phone
              </h5>
              <p>+91 84779 26778, +91 84593 40273</p>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-sm-4 col-md-3">
                {" "}
                <img
                  src={QR}
                  alt="QRcode"
                  style={{
                    width: "100%",
                    maxWidth: "100px",
                    height: "auto",
                    marginLeft: 10,
                  }}
                />
              </div>
              <div
                className="col-sm-8 col-md-8"
                style={{
                  paddingTop: "1rem",
                  color: "gray",
                  fontFamily: "cursive",
                }}
              >
                <p className="text-center text-sm-left">
                  Scan the QR Code to directly locate Us
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocateUs;
