import React from "react";

function Footer() {
  return (
    <footer
      className="py-3"
      style={{
        marginTop: 30,
        backgroundColor: "edf1f7",
        borderTop: "1px solid black",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-4">
            <p className="text-muted mb-0">&copy; 2024, All Rights Reserved </p>
          </div>
          <div className="col-md-6 col-sm-8 text-end">
            <p className="mb-0">
              <a href="#" className="text-decoration-none me-3">
                Terms & Conditions
              </a>
              <a href="#" className="text-decoration-none">
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
