import React, { useState } from "react";
import { PersonFill, EnvelopeFill, TelephoneFill } from "react-bootstrap-icons";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { isValidEmail, isValidPhoneNumber } from "../Utils/validFormDetails";

function AdmissionQuery() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    console.log("Form data", formData);

    if (!formData.name || !formData.email || !formData.phone) {
      toast.error("Please fill out all fields.");
      setLoading(false);
      return;
    }
    if (!isValidEmail(formData.email)) {
      toast.error("Please provide a valid email.");
      setLoading(false);
      return;
    }
    if (!isValidPhoneNumber(formData.phone)) {
      toast.error("Please provide a valid 10-digit phone number");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post("../api/inquiry.php", formData);
      toast.success(
        "Thank you for your interest, we will contact you shortly."
      );
      setFormData({
        name: "",
        email: "",
        phone: "",
      });
    } catch (err) {
      console.log(err);
      toast.error("Error submitting form. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const isFormInvalid = () => {
    return !formData.name || !formData.email || !formData.phone;
  };

  return (
    <>
      <div id="admission" className="mt-5 container" style={{ width: "75%" }}>
        <div className="text-center">
          <h4 style={{ fontWeight: "bold" }}>Admission Inquiry</h4>
        </div>
        <div
          className="card mt-3 mb-3"
          style={{ border: "1px solid black", borderRadius: 20 }}
        >
          <div className="row mt-3 container" style={{ marginLeft: 20 }}>
            <div className="col-md-5">
              <div className="row">
                <div className="col">
                  <h5
                    style={{
                      paddingLeft: 0,
                      paddingTop: 30,
                      paddingRight: 30,
                      color: "gray",
                    }}
                  >
                    Contact us at the following phone numbers or leave your
                    contact information and we'll help your child achieve
                    Excellence! <br />
                    <br />
                    <font color="black">
                      <TelephoneFill style={{ paddingLeft: 0 }} />
                      Phone
                      <p>
                        +91 84779 26778
                        <br />
                        +91 84593 40273
                      </p>
                    </font>
                  </h5>
                </div>
              </div>
            </div>{" "}
            <div className="col-md-7">
              <form
                style={{
                  padding: 20,
                  maxWidth: "70%",
                  marginLeft: 20,
                }}
                onSubmit={handleSubmit}
                methos="post"
              >
                <div className="mb-3 input-group">
                  <span className="input-group-text">
                    <PersonFill />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ borderRadius: "20px 0 0 20px" }}
                    placeholder="First and last name"
                  />
                </div>
                <div className="mb-3 input-group">
                  <span className="input-group-text">
                    <EnvelopeFill />
                  </span>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{ borderRadius: "0" }}
                    placeholder="Email"
                  />
                </div>
                <div className="mb-3 input-group">
                  <span className="input-group-text">
                    <TelephoneFill />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    style={{ borderRadius: "0 20px 20px 0" }}
                    placeholder="Phone Number"
                  />
                </div>
                <button
                  type="submit"
                  className="btn w-100"
                  style={{
                    backgroundColor: "purple",
                    color: "white",
                    height: 50,
                    borderRadius: "0 0 20px 20px",
                  }}
                  disabled={loading || isFormInvalid()}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
                <p style={{ fontSize: 14, color: "gray", margin: 10 }}>
                  By clicking the "Submit" button, you agree that we may contact
                  you by phone, email, or text message
                </p>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
        />
      </div>
    </>
  );
}

export default AdmissionQuery;
