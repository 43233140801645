import React from "react";

function WhyUs() {
  return (
    <div id="whyUs" className="mt-5 container">
      <div className="text-center">
        <h4 style={{ fontWeight: "bold" }}>Why Us ?</h4>
      </div>
      <p
        className="mt-3 mb-3"
        style={{ width: "75%", marginLeft: "auto", marginRight: "auto" }}
      >
        <ul>
          <li>Holistic Approach to Learning</li>
          <li>Play & Activity Based Learning</li>
          <li>Nurturing Environment</li>
          <li>Experienced Educators</li>
          <li>Safety & Security</li>
          <li>Regular Parents Involvement</li>
          <li>Technology Enhanced Learning</li>
          <li>Mini Library</li>
        </ul>
      </p>
    </div>
  );
}

export default WhyUs;
