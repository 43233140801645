import "./App.css";
import Main from "./Components/Main";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MemoriesPage from "./Components/MemoriesPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/memories",
    element: <MemoriesPage />,
  },
]);
function App() {
  return (
    <RouterProvider router={router}>
      <div className="App">
        <Main />
      </div>
    </RouterProvider>
  );
}

export default App;
