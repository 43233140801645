import React from "react";
import topBannerImage from "../Assests/TopBanner.png";

function Banner() {
  return (
    <img
      src={topBannerImage}
      alt="Top Banner"
      style={{ height: "50%", width: "100%" }}
    />
  );
}

export default Banner;
