import React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import carousel1 from "../Assests/1.jpeg";
import carousel2 from "../Assests/2.jpeg";
import carousel3 from "../Assests/3.jpeg";
import carousel4 from "../Assests/4.jpeg";
import carousel5 from "../Assests/5.jpeg";
import carousel6 from "../Assests/6.jpeg";
import carousel7 from "../Assests/7.jpeg";
import carousel8 from "../Assests/8.jpeg";
import carousel9 from "../Assests/9.jpeg";
import carousel10 from "../Assests/10.jpeg";
import carousel11 from "../Assests/11.jpeg";
import carousel12 from "../Assests/12.jpeg";
import carousel13 from "../Assests/13.jpeg";
import carousel14 from "../Assests/14.jpeg";
import carousel15 from "../Assests/15.jpeg";
import carousel16 from "../Assests/16.jpeg";
import carousel17 from "../Assests/17.jpeg";
import carousel18 from "../Assests/18.jpeg";
import carousel19 from "../Assests/19.jpeg";
import carousel20 from "../Assests/20.jpeg";
import carousel21 from "../Assests/21.jpeg";
import carousel22 from "../Assests/22.jpeg";
import carousel23 from "../Assests/23.jpeg";
import carousel24 from "../Assests/24.jpeg";
import carousel25 from "../Assests/25.jpeg";
import carousel26 from "../Assests/26.jpeg";
import carousel27 from "../Assests/27.jpeg";
import carousel28 from "../Assests/28.jpeg";
import carousel29 from "../Assests/29.jpeg";
import carousel30 from "../Assests/30.jpeg";
import carousel31 from "../Assests/31.jpeg";
import carousel32 from "../Assests/32.jpeg";
import carousel33 from "../Assests/33.jpeg";
import carousel34 from "../Assests/34.jpeg";
import carousel35 from "../Assests/35.jpeg";
import carousel36 from "../Assests/36.jpeg";
import carousel37 from "../Assests/37.jpeg";
import carousel38 from "../Assests/38.jpeg";
import carousel39 from "../Assests/39.jpeg";
import carousel40 from "../Assests/40.jpeg";
import carousel41 from "../Assests/41.jpeg";
import carousel42 from "../Assests/42.jpeg";
import carousel43 from "../Assests/43.jpeg";
import carousel44 from "../Assests/44.jpeg";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

 export const images = [
  carousel1,
  carousel2,
  carousel3,
  carousel4,
  carousel5,
  carousel6,
  carousel32,
  carousel33,
  carousel34,
  carousel35,
  carousel36,
  carousel7,
  carousel8,
  carousel9,
  carousel10,
  carousel11,
  carousel12,
  carousel13,
  carousel14,
  carousel15,
  carousel16,
  carousel17,
  carousel18,
  carousel19,
  carousel27,
  carousel28,
  carousel29,
  carousel20,
  carousel21,
  carousel22,
  carousel23,
  carousel24,
  carousel25,
  carousel26,
  carousel30,
  carousel31,
  carousel37,
  carousel38,
  carousel39,
  carousel40,
  carousel41,
  carousel42,
  carousel43,
  carousel44,
];

// const kids = [carousel1, carousel2, carousel3, carousel4, carousel5];
// const names = ["Ravi", "Amit", "Ananya", "Keshav", "Kavi"];

function OurMemories({ deviceType }) {
  const navigate = useNavigate();

  const handleMoreClick = () => {
    navigate("/memories");
  };

  const renderImages = () => {
    return images.map((image, index) => (
      <div key={index} style={{ marginLeft: 10, marginRight: 10 }}>
        <img
          src={image}
          className="d-block w-100"
          alt={`carousel${index}`}
          style={{ height: "400px", width: "auto", borderRadius: 10 }}
        />
      </div>
    ));
  };

  // const renderFirstDay = () => {
  //   return kids.map((image, index) => (
  //     <div key={index} style={{ marginLeft: 10, marginRight: 10 }}>
  //       <img
  //         src={image}
  //         className="d-block w-100"
  //         alt={`carousel${index}`}
  //         style={{ height: "200px", width: "auto", borderRadius: 10 }}
  //       />
  //       <div className="text-center mb-3">
  //         <h4 style={{ fontWeight: "bold" }}>{names[index]}</h4>
  //       </div>
  //     </div>
  //   ));
  // };

  return (
    <div id="ourmemo" className="mt-5 container">
      <div className="text-center mb-3">
        <h4 style={{ fontWeight: "bold" }}>Our Memories</h4>
      </div>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={deviceType !== "mobile" ? true : false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["mobile"]}
        deviceType={deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {renderImages()}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
          }}
        >
          <button
            onClick={handleMoreClick}
            style={{
              height: "50px",
              width: "150px",
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f5f5f5",
              border: "1px solid #ddd",
              cursor: "pointer",
            }}
          >
            <h4 style={{ fontWeight: "bold" }}>More ...</h4>
          </button>
        </div>
      </Carousel>
    </div>
  );
}

export default OurMemories;
