import React from "react";

function AboutUs() {
  return (
    <div id="aboutUs" className="mt-5 container">
      <div className="text-center">
        <h4 style={{ fontWeight: "bold" }}>About Us</h4>
      </div>
      <p
        className="mt-3 mb-3"
        style={{ width: "75%", marginLeft: "auto", marginRight: "auto" }}
      >
        Midas is a legendary Phrygian king who is given the power of turning
        everything he touches to gold. <br />
        We at <b>Young Midas</b>, strive to give a midas touch to every child to
        turn up successful in future. <b>Young Midas</b> is an unique conceptual
        school where learning is based on participation. We believe in activity
        based learning where kids learn and grow with music, dance and drama
        techniques. They will participate and experience the life skills
        learning within different situation through role play, storytelling,
        singing, dancing and improvisations e.t.c. <br />
        <br />
        <b>At Young Midas</b> - <br />
        <ul>
          <li>
            {" "}
            Each child is given equal priority and we assure their safety as in
            their mother's hand.
          </li>
          <li>
            Children are nurtured in a loving and caring environment, which
            helps in easing them smoothly into the normal routine of school.{" "}
          </li>
          <li>
            We stimulate the child imagination and impart them with life skills
            in a music loving atmosphere for their all round development.
          </li>
        </ul>
      </p>
    </div>
  );
}

export default AboutUs;
