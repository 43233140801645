import React, { useState } from "react";
import programe1 from "../Assests/programe1.png";
import programe2 from "../Assests/programe2.png";
import programe3 from "../Assests/programe3.png";
import programe4 from "../Assests/programe4.png";
import { ChevronRight } from "react-bootstrap-icons";

const programs = [
  {
    image: programe1,
    title: "PlayGroup",
    ageGroup: "1.5-2.5 Years",
    duration: "2.5 Hours/Day",
    info: "The first few years of a child’s life are crucial and critical in terms of acquisition of skills and brain based learning. In Playgroup, children learn how to sit, how to walk, how to speak, how to behave in public, how to make friends, how to share things with others, etc. All the above activities help children develop personality and nature.",
  },
  {
    image: programe2,
    title: "Nursery",
    ageGroup: "2.5-3.5 Years",
    duration: "3 Hours/Day",
    info: "The nursery education engages children in school readiness activities such as reading, writing, counting, number recognition, and problem solving. Children learn in a fun-filled but purposeful manner. Apart from reading and writing, kids are also taught core concepts. Children are trained to intuitively grasp calendars, drawing, cutting, hygiene, nature, numbers, listening, weather, and other vital concepts. Multifunctional understanding of different types of concepts is also imparted.",
  },
  {
    image: programe3,
    title: "Junior Kindergarten",
    ageGroup: "3.5-4.5 Years",
    duration: "3.5 Hours/Day",
    info: "Children learn age-appropriate activities in various areas like English language, numbers, general knowledge, music, physical development, and art. Our proprietary programmes for Phonics and school readiness makes it one of the best of its kind. Child development is observed and assessed throughout the year using a well-designed progressive matrix.",
  },
  {
    image: programe4,
    title: "Senior Kindergarten",
    ageGroup: "4.5-5.5 Years",
    duration: "4 Hours/Day",
    info: "Proper writing patterns are taught to enhance their pincer muscles. Children continue to develop a wide range of skills as they graduate to Senior KG including physical, social, emotional, language and literary and cognitive skills. They are exposed to form proper sentences along with the correct grammar. Pre-Math skills are taught in an experiential manner using different resources.",
  },
];

function Programmes() {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div id="programmes" className="mt-5 container">
      <div className="text-center">
        <h4 style={{ fontWeight: "bold" }}>Our Programmes</h4>
      </div>
      <div className="d-flex flex-wrap justify-content-between mt-3 mb-3">
        {programs.map((program, index) => (
          <div
            key={index}
            className="programme-card d-flex align-items-center mb-3"
          >
            <div className="d-md-none w-100">
              <div className="programme-image col-12 col-sm-6 col-md-6 mr-3">
                <img
                  src={program.image}
                  alt={program.title}
                  style={{
                    width: 200,
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="programme-details col-12 col-sm-6 col-md-6 mr-3 mt-3 mt-sm-2">
                <h5
                  className="card-title"
                  style={{ color: "#7241B3", fontWeight: "bold" }}
                >
                  {program.title}
                </h5>
                <p
                  className="card-text"
                  style={{ color: "#9665D7", marginTop: 20 }}
                >
                  <strong>Age Group: </strong> {program.ageGroup}
                </p>
                <p className="card-text mt-sm-0" style={{ color: "#9665D7" }}>
                  <strong>Duration: </strong> {program.duration}
                </p>
              </div>
              <div className="programme-info col-12 mt-3">
                {expanded ? program.info : program.info.slice(0, 100) + "... "}
                <button
                  className="btn btn-sm btn-outline-primary mt-1"
                  style={{ borderRadius: 20 }}
                  onClick={toggleExpand}
                >
                  {expanded ? "Show less" : "Learn more"} <ChevronRight />
                </button>
              </div>
            </div>

            <div className="d-none d-md-flex align-items-start">
              <div className="programme-image col-md-4 col-lg-3 col-xl-3 mr-3">
                <img
                  src={program.image}
                  alt={program.title}
                  style={{
                    width: 200,
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="programme-details  col-md-4 col-lg-3 col-xl-3 mr-3">
                <h5
                  className="card-title"
                  style={{ color: "#7241B3", fontWeight: "bold" }}
                >
                  {program.title}
                </h5>
                <p
                  className="card-text"
                  style={{ color: "#9665D7", marginTop: 15 }}
                >
                  <strong>Age Group: </strong> {program.ageGroup}
                </p>
                <p className="card-text" style={{ color: "#9665D7" }}>
                  <strong>Duration: </strong> {program.duration}
                </p>
              </div>
              <div
                className="programme-info col-md-4 col-lg-6 col-xl-6"
                style={{ marginLeft: 20 }}
              >
                <p className="card-text">{program.info}</p>
                <button
                  className="btn btn-sm btn-outline-primary mt-1"
                  style={{ borderRadius: 20 }}
                >
                  Learn more <ChevronRight />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Programmes;
