import React, { useState } from "react";
import Logo from "../Assests/Logo.png";
import {
  House,
  ClockHistory,
  Collection,
  People,
  GeoAlt,
  Envelope,
  QuestionCircle,
} from "react-bootstrap-icons";

function Navbar() {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleSmoothScroll = (id) => {
    if (isToggled) {
      setIsToggled(false);
    }

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top`}
      style={{
        backgroundColor: isToggled ? "white" : "rgba(255, 255, 255, 0.1)",
      }}
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img src={Logo} alt="Logo" style={{ height: 50, width: 50 }} />
        </a>
        <button
          className={`navbar-toggler ${isToggled ? "" : "collapsed"}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarTogglerDemo03"
          aria-expanded={isToggled ? "true" : "false"}
          aria-label="Toggle navigation"
          onClick={handleToggle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isToggled ? "show" : ""}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => handleSmoothScroll("aboutUs")}
              >
                <House /> About Us
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => handleSmoothScroll("whyUs")}
              >
                <QuestionCircle /> Why Us
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => handleSmoothScroll("ourmemo")}
              >
                <ClockHistory /> Our Memories
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => handleSmoothScroll("programmes")}
              >
                <Collection /> Our Programmes
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => handleSmoothScroll("admission")}
              >
                <People /> Admission Inquiry
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => handleSmoothScroll("locate")}
              >
                <GeoAlt /> Locate Us
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link btn btn-link"
                onClick={() => handleSmoothScroll("locate")}
              >
                <Envelope /> Contact Us
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
