export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPhoneNumber = (number) => {
  console.log(number);
  const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(number);
};
