import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import Zoom from "react-medium-image-zoom";
import "react-multi-carousel/lib/styles.css";
import "react-medium-image-zoom/dist/styles.css";
import carousel1 from "../Assests/1.jpeg";
import carousel2 from "../Assests/2.jpeg";
import carousel3 from "../Assests/3.jpeg";
import carousel4 from "../Assests/4.jpeg";
import carousel5 from "../Assests/5.jpeg";
import carousel6 from "../Assests/6.jpeg";
import carousel7 from "../Assests/7.jpeg";
import carousel8 from "../Assests/8.jpeg";
import carousel9 from "../Assests/9.jpeg";
import carousel10 from "../Assests/10.jpeg";
import carousel11 from "../Assests/11.jpeg";
import carousel12 from "../Assests/12.jpeg";
import carousel13 from "../Assests/13.jpeg";
import carousel14 from "../Assests/14.jpeg";
import carousel15 from "../Assests/15.jpeg";
import carousel16 from "../Assests/16.jpeg";
import carousel17 from "../Assests/17.jpeg";
import carousel18 from "../Assests/18.jpeg";
import carousel19 from "../Assests/19.jpeg";
import carousel20 from "../Assests/20.jpeg";
import carousel21 from "../Assests/21.jpeg";
import carousel22 from "../Assests/22.jpeg";
import carousel23 from "../Assests/23.jpeg";
import carousel24 from "../Assests/24.jpeg";
import carousel25 from "../Assests/25.jpeg";
import carousel26 from "../Assests/26.jpeg";
import carousel27 from "../Assests/27.jpeg";
import carousel28 from "../Assests/28.jpeg";
import carousel29 from "../Assests/29.jpeg";
import carousel30 from "../Assests/30.jpeg";
import carousel31 from "../Assests/31.jpeg";
import carousel32 from "../Assests/32.jpeg";
import carousel33 from "../Assests/33.jpeg";
import carousel34 from "../Assests/34.jpeg";
import carousel35 from "../Assests/35.jpeg";
import carousel36 from "../Assests/36.jpeg";
import carousel37 from "../Assests/37.jpeg";
import carousel38 from "../Assests/38.jpeg";
import carousel39 from "../Assests/39.jpeg";
import carousel40 from "../Assests/40.jpeg";
import carousel41 from "../Assests/41.jpeg";
import carousel42 from "../Assests/42.jpeg";
import carousel43 from "../Assests/43.jpeg";
import carousel44 from "../Assests/44.jpeg";
import video1 from "../Assests/video1.mp4";
import video2 from "../Assests/video2.mp4";
import video3 from "../Assests/video3.mp4";
import Logo from "../Assests/Logo.png";
import { useNavigate } from "react-router-dom";

const occasions = {
  "First Day": {
    media: [
      { type: "image", src: carousel1, name: "Ravi" },
      { type: "image", src: carousel2, name: "Amit" },
      { type: "image", src: carousel3, name: "Ananya" },
      { type: "image", src: carousel4, name: "Keshav" },
      { type: "image", src: carousel5, name: "Kavi" },
    ],
  },
  "Mother's Day": {
    media: [
      { type: "video", src: video1, name: "Mom1" },
      { type: "image", src: carousel6, name: "Mom2" },
      { type: "image", src: carousel7, name: "Mom3" },
      { type: "image", src: carousel8, name: "Mom4" },
      { type: "image", src: carousel9, name: "Mom5" },
    ],
  },
  "Father's Day": {
    media: [
      { type: "image", src: carousel10, name: "Father1" },
      { type: "image", src: carousel11, name: "Father2" },
      { type: "video", src: video2, name: "Father3" },
      { type: "image", src: carousel12, name: "Father4" },
      { type: "image", src: carousel13, name: "Father5" },
    ],
  },
  "Annual Day": {
    media: [
      { type: "image", src: carousel14, name: "Event1" },
      { type: "image", src: carousel15, name: "Event2" },
      { type: "image", src: carousel16, name: "Event3" },
      { type: "image", src: carousel17, name: "Event4" },
      { type: "video", src: video3, name: "Event5" },
    ],
  },
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const renderMedia = (media) => {
  return media.map((item, index) => {
    if (item.type === "image") {
      return (
        <div key={index} style={{ marginLeft: 10, marginRight: 10 }}>
          <Zoom>
            <img
              src={item.src}
              className="d-block w-100"
              alt={`media${index}`}
              style={{
                height: "250px",
                width: "auto",
                borderRadius: 10,
                cursor: "pointer",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                transition: "transform 0.3s ease-in-out",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            />
          </Zoom>
          <div className="text-center mb-3">
            <h5 style={{ fontWeight: "bold", marginTop: 10 }}>{item.name}</h5>
          </div>
        </div>
      );
    } else if (item.type === "video") {
      return (
        <div key={index} style={{ marginLeft: 10, marginRight: 10 }}>
          <video
            width="100%"
            height="250px"
            style={{
              borderRadius: 10,
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              transition: "transform 0.3s ease-in-out",
            }}
            onClick={(e) => e.currentTarget.play()}
            onMouseOver={(e) =>
              (e.currentTarget.style.transform = "scale(1.05)")
            }
            onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            controls
          >
            <source src={item.src} type="video/mp4" />
          </video>
          <div className="text-center mb-3">
            <h5 style={{ fontWeight: "bold", marginTop: 10 }}>{item.name}</h5>
          </div>
        </div>
      );
    }
    return null;
  });
};

function MemoriesPage() {
  const [selectedOccasion, setSelectedOccasion] = useState("First Day");
  const navigate = useNavigate();
  const handleOccasionClick = (occasion) => {
    setSelectedOccasion(occasion);
  };

  const { media } = occasions[selectedOccasion];

  return (
    <div
      id="memories-page"
      className="mt-5"
      style={{ marginLeft: 10, marginRight: 10 }}
    >
      <div className="row">
        <div className="col-md-2">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <img src={Logo} alt="Logo" style={{ height: 50, width: 50 }} />
            <h4
              style={{
                textAlign: "center",
                marginTop: "5px",
                marginLeft: "10px",
              }}
            >
              Memories
            </h4>
          </div>

          <ul className="list-group">
            {Object.keys(occasions).map((occasion) => (
              <li
                key={occasion}
                className={`list-group-item ${
                  selectedOccasion === occasion ? "active" : ""
                }`}
                onClick={() => handleOccasionClick(occasion)}
                style={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedOccasion === occasion ? "#E6E6FA" : "",
                  color: selectedOccasion === occasion ? "black" : "",
                  border: "1px solid #E6E6FA",
                  transition: "all 0.3s ease-in-out",
                }}
                onMouseOver={(e) => {
                  if (selectedOccasion !== occasion) {
                    e.currentTarget.style.backgroundColor = "#f0f0f0";
                  }
                }}
                onMouseOut={(e) => {
                  if (selectedOccasion !== occasion) {
                    e.currentTarget.style.backgroundColor = "";
                  }
                }}
              >
                {occasion}
              </li>
            ))}
          </ul>
          <button
            className="btn w-100"
            style={{
              height: "50px",
              marginTop: 20,
              borderRadius: "0 0 20px 20px",
              backgroundColor: "purple",
              color: "white",
              border: "1px solid #ffffff",
              fontWeight: "bold",
            }}
            onClick={() => navigate("/")}
          >
            Back
          </button>
        </div>
        <div className="col-md-10" style={{ marginTop: "10px" }}>
          <div className="text-center mb-3">
            <h4 style={{ fontWeight: "bold", color: "purple" }}>
              {selectedOccasion} celebrations at YoungMidas
            </h4>
          </div>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            autoPlay={true}
            autoPlaySpeed={2500}
            infinite={true}
            keyBoardControl={true}
            customTransition="transform 300ms ease-in-out"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            removeArrowOnDeviceType={["mobile"]}
          >
            {renderMedia(media)}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default MemoriesPage;
